<template>
  <div>

    <div class="common">
      <div class="common-header">
        <div class="serch">
          <div class="select-serch">
            <div style="margin-top: 15px;">
              <el-input placeholder="请输入内容" v-model="input" class="input-with-select" clearable @clear="fetchDataByOne">
                <el-select v-model="select" slot="prepend" placeholder="请选择" style="width: 120px">
                  <el-option label="产品编码" value="1"></el-option>
                  <el-option label="款式" value="2"></el-option>
                  <el-option label="尺码" value="3"></el-option>
                  <el-option label="英文名" value="4"></el-option>
                  <el-option label="内部尺码" value="5"></el-option>
                  <el-option label="系列" value="7"></el-option>

                </el-select>
                <el-button slot="append" icon="el-icon-search" @click="fetchData"></el-button>
              </el-input>
            </div>
          </div>
        </div>
        <div class="tow-button">
          <div class="button">
            <ProductAddDialog @addGoods="getAddGoodsEvent"></ProductAddDialog>
          </div>
          <div class="button">
            <el-button type="primary" @click="downloadGoodsTemplateRequest"
                       :loading="isDownload"
                       :disabled="isDownload">下载模板</el-button>

          </div>
          <div class="button">
            <input
                type="file"
                ref="fileInput"
                style="display: none"
                @change="handleFileChange"


            />

              <el-button type="primary" @click="triggerFileSelect"
                         :loading="isUploading"
                          :disabled="isUploading"

              >批量上传</el-button>


          </div>
        </div>

      </div>
      <div class="common-middle">
        <div class="bar"></div>
        <div class="table">
          <el-table
              :data="tableData"
              style="width: 100%"
              border>
            <el-table-column
                width="70px"
                prop="n"
                label="序号">
              <template slot-scope="scope">
                {{ ( scope.$index + 1).toString().padStart(2, '0') }}
              </template>
            </el-table-column>
            <el-table-column
                prop="goods"
                label="产品编码"
                >
            </el-table-column>
            <el-table-column
                prop="style"
                label="款式"
                >
            </el-table-column>
            <el-table-column
                prop="englishName"
                label="英文名"
            >
            </el-table-column>
            <el-table-column
                prop="size"
                label="尺码"
                width="100px"
                >
            </el-table-column>

<!--            <el-table-column
                prop="dSize"
                label="数字尺码"
                width="100px"
            >
            </el-table-column>-->

            <el-table-column
                prop="kind"
                label="类型"
                width="200px"
                >
              <template slot-scope="scope">
                <span v-if="scope.row.kind === 1">喷油—装配</span>
                <span v-else-if="scope.row.kind === 2">贴花—喷油—装配</span>


              </template>
            </el-table-column>

            <el-table-column
                prop="inSize"
                label="内部尺码"
                width="100px"
                >

            </el-table-column>



            <el-table-column
                prop="series"
                label="系列"
                width="120px"
                >
            </el-table-column>
            <el-table-column
                width="100px"
                label="操作"
            >

              <template slot-scope="scope">
                <edit-goods :row="scope.row" @editGoods="getEditGoodsEvent" @deleteGoods="getDeleteEvent"></edit-goods>
              </template>

            </el-table-column>
          </el-table>
        </div>

      </div>
      <div class="common-foot">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            class="custom-pagination"
            style="margin-left: auto;margin-top: 15px;margin-right: 15px">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import ProductAddDialog from "@/components/ProductAddDialog.vue";
import EditGoods from "@/components/EditGoods.vue";

export default {

  created() {
    this.fetchData();
  },
  methods:{
    removeButtonFocus() {
      const button = this.$refs.uploadButton;
      if (button) {
        button.blur(); // 手动移除按钮焦点
      }
    },

    // 点击按钮触发文件选择框
    triggerFileSelect() {
      this.$refs.fileInput.click(); // 触发文件输入框点击
    },
    // 处理文件选择
    handleFileChange(event) {
      const file = event.target.files[0]; // 获取选中的文件
      if (file) {
        this.fileName = file.name; // 保存文件名
        this.uploadFile(file); // 调用上传方法
      }

      // 重置文件输入框的值
      event.target.value = '';
    },
    // 文件上传
    async uploadFile(file) {
      this.isUploading = true; // 开始上传，按钮显示加载状态

      // 创建 FormData 对象
      const formData = new FormData();
      formData.append('file', file); // 将文件加入到表单数据中

      // 使用 Axios 发送 POST 请求
      this.$axios.post(process.env.VUE_APP_BASE_API + '/api/upload/goodsExcel', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // 设置请求头为 multipart/form-data
        },
      })
          .then(response => {
            // 上传成功的处理
            if(response.data.success){
              this.$message({
                type:"success",
                message:"上传成功",
              });
              this.fetchDataByOne();
            }else {
              this.$message.error("上传失败");
            }


          })
          .catch(() => {
            // 上传失败的处理
            this.$message.error("上传失败");
          }
          ).finally(() => {
        // 无论成功还是失败，最终都还原按钮状态
        this.isUploading = false;
      });
    },



    downloadGoodsTemplateRequest(){
      this.isDownload = true;

      this.$axios.get(process.env.VUE_APP_BASE_API+'/api/download/goodsTemplate',{responseType: 'blob'})
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `template.xlsx`); // 设置下载文件名
            document.body.appendChild(link);
            link.click();

          })
          .catch(error => {
            console.error('Error:', error);
          }).finally(() => {
            this.isDownload = false;
      });
    },

    //页面数据条数改变
    handleSizeChange(size){
      this.pageSize = size;
      this.fetchData();
    },
    //当前页改变
    handleCurrentChange(page){
      this.currentPage = page;
      this.fetchData();
    },

    //查询列表
    fetchData() {
      var form = {}

      if(this.select == '1'){
        form = {
          pageSize:this.pageSize,
          currentPage:this.currentPage,
          goods:this.input
        }
      }

      if(this.select == '2'){
        form = {
          pageSize:this.pageSize,
          currentPage:this.currentPage,
          style:this.input
        }
      }

      if(this.select == '3'){
        form = {
          pageSize:this.pageSize,
          currentPage:this.currentPage,
          size:this.input
        }
      }

      if(this.select == '4'){
        form = {
          pageSize:this.pageSize,
          currentPage:this.currentPage,
          englishName:this.input
        }
      }

      if(this.select == '5'){
        form = {
          pageSize:this.pageSize,
          currentPage:this.currentPage,
          inSize:this.input
        }
      }



      if(this.select == '7'){
        form = {
          pageSize:this.pageSize,
          currentPage:this.currentPage,
          series:this.input
        }
      }

      this.$axios.get(process.env.VUE_APP_BASE_API+'/api/goods/find',{params:form})
          .then(response => {
              if(response.data.data.goodsList.length == 0 && this.currentPage > 1){
                this.fetchDataByOne();
              }else {
                this.tableData = response.data.data.goodsList;
                this.total = response.data.total;
              }

          })
          .catch(error => {
            console.error('Error:', error);
          });
    },
    //回到第一页
    fetchDataByOne() {
      var form = {}
      this.currentPage = 1;

      if(this.select == '1'){
        form = {
          pageSize:this.pageSize,
          currentPage:this.currentPage,
          goods:this.input
        }
      }

      if(this.select == '2'){
        form = {
          pageSize:this.pageSize,
          currentPage:this.currentPage,
          style:this.input
        }
      }

      if(this.select == '3'){
        form = {
          pageSize:this.pageSize,
          currentPage:this.currentPage,
          size:this.input
        }
      }

      if(this.select == '4'){
        form = {
          pageSize:this.pageSize,
          currentPage:this.currentPage,
          englishName:this.input
        }
      }

      if(this.select == '5'){
        form = {
          pageSize:this.pageSize,
          currentPage:this.currentPage,
          inSize:this.input
        }
      }



      if(this.select == '7'){
        form = {
          pageSize:this.pageSize,
          currentPage:this.currentPage,
          series:this.input
        }
      }

      this.$axios.get(process.env.VUE_APP_BASE_API+'/api/goods/find',{params:form})
          .then(response => {


            this.tableData = response.data.data.goodsList;
            this.total = response.data.total;

          })
          .catch(error => {
            console.error('Error:', error);
          });
    },
    //点击按钮导航到贴花页面
    goToAppliquePage() {
      this.$router.push({ name: 'applique' });
    },
    //获取添加事件
    getAddGoodsEvent(success){
      if(success){
        this.$message({
          type:"success",
          message:"添加成功",
        });
        this.fetchDataByOne();
      }else {
        this.$message.error("添加失败")
        this.fetchDataByOne();
      }
    },
    //获取编辑事件
    getEditGoodsEvent(success){
      if(success){
        this.$message({
          type:"success",
          message:"编辑成功",
        });
        this.fetchDataByOne();
      }else {
        this.$message.error("编辑失败")
        this.fetchDataByOne();
      }
    },
    //获取删除事件
    getDeleteEvent(success){
      if(success){
        this.$message({
          type:"success",
          message:"删除成功",
        });
        this.fetchDataByOne();
      }else {
        this.$message.error("删除失败")
        this.fetchDataByOne();
      }
    }
  },
  components: {EditGoods, ProductAddDialog},
  data() {
    return {
      tableData: [],
      input:'',
      select:'1',
      pageSize:10,
      currentPage:1,
      total:0,
      isUploading:false,
      isDownload:false,
    }
  }
}
</script>

<style scoped>
/* 样式可以根据需要添加 */

.custom-pagination >>> .el-pagination__total {
  color: black;
}

.custom-pagination >>> .el-pagination__jump {
  color: black;
}

.common{
  width: 100%;
  height: 100%;

}

.common .common-header{
  width: 100%;


  display: flex;
  justify-content: flex-start; /* 水平靠左排列 */
  align-items: flex-end;
  flex-direction: column;
}
.common .common-header .serch{
  background: #4B6EA8;
  width: 100%;
  height: 80px;

  display: flex;
  justify-content: center; /* 水平居中 */

}
.common .common-header .serch .select-serch{
  width: 500px;
  height: 70px;

}


.common .common-header .tow-button{
  width: 100%;
  height: 80px;

  display: flex;
  justify-content: flex-start; /* 水平靠左排列 */
  align-items: flex-end; /* 垂直靠下排列 */
}

.common .common-header .tow-button .button{
  width: 100px;
  height: 30px;

  margin-bottom: 15px;
  margin-left: 15px;
}

.common .common-middle{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

}

.bar{
  width: 100%;
  height: 50px;
  background: #4B6EA8;
}


.common .common-middle .table{
  width: 90%;
  min-width: 1000px;
  margin-right: auto;
  margin-left: auto;

}
.common .common-foot{
  background: #4B6EA8;
  width: 100%;
  display: flex;
}

</style>
