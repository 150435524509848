<template>
  <div>

    <div class="common">
      <div class="common-header">
        <div class="serch">

        </div>

      </div>
      <div class="common-middle">

        <div class="table">

          <el-divider content-position="left">订单项</el-divider>

          <el-descriptions class="margin-top"  :column="6"  border>
            <template slot="extra">

            </template>
            <el-descriptions-item :span="1">
              <template slot="label">

                订单号
              </template>
              <div style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                {{this.order.poId}}
              </div>
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label">

                订单数量
              </template>
              <div style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                {{this.order.quantity}}
              </div>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">

                已出货
              </template>
              <div style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                {{this.order.shipment}}
              </div>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">

                库存
              </template>
              <div style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                <a href="javascript:void(0);" @click="toInventoryOrderItem(order.poId)">{{ this.order.inventory }}</a>
              </div>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">

                应绑定数量
              </template>
              <div style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                {{this.order.quantity+this.order.scrap}}
              </div>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">

                实际投入
              </template>
              <div style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">

                <a href="javascript:void(0);" @click="toRealInputOrderItem(order.poId)"> {{this.order.realInput}}</a>
              </div>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">

                总返工次数
              </template>
              <div style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                <a href="javascript:void(0);" @click="toReworkOrderItem(order.poId)">{{ order.rework }}</a>

              </div>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">

                已报废
              </template>
              <div style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                <a href="javascript:void(0);" @click="toScrapOrderItem(order.poId)"> {{this.order.scrap}}</a>
              </div>
            </el-descriptions-item>
            <el-descriptions-item :span="4">
            </el-descriptions-item>



            <el-descriptions-item :span="6">
              <template slot="label">
                喷油
              </template>
              <el-descriptions class="margin-top"  :column="3"  border>
                <el-descriptions-item >
                  <template slot="label">
                    已完成
                  </template>
                  <div style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    {{this.order.sprayComplete}}
                  </div>
                </el-descriptions-item>
                <el-descriptions-item >
                  <template slot="label">
                    返工中
                  </template>
                  <div style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    {{this.order.sprayRework}}
                  </div>
                </el-descriptions-item>
                <el-descriptions-item >
                  <template slot="label">
                    已报废
                  </template>
                  <div style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    {{this.order.sprayScrap}}
                  </div>
                </el-descriptions-item>
              </el-descriptions>
            </el-descriptions-item>


            <el-descriptions-item :span="6">
              <template slot="label">
                贴花
              </template>
              <el-descriptions class="margin-top"  :column="3"  border>
                <el-descriptions-item>
                  <template slot="label">
                    已完成
                  </template>
                  <div style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    {{this.order.appliqueComplete}}
                  </div>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    返工中
                  </template>
                  <div style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    {{this.order.appliqueRework}}
                  </div>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    已报废
                  </template>
                  <div style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    {{this.order.appliqueScrap}}
                  </div>
                </el-descriptions-item>
              </el-descriptions>
            </el-descriptions-item>

<!--            <el-descriptions-item :span="6">
              <template slot="label">
                装配
              </template>
              <el-descriptions class="margin-top"  :column="3"  border>
                <el-descriptions-item>
                  <template slot="label">
                    已完成
                  </template>
                  <div style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    {{this.order.assembleComplete}}
                  </div>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    返工中
                  </template>
                  <div style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    {{this.order.assembleRework}}
                  </div>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    已报废
                  </template>
                  <div style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    {{this.order.assembleScrap}}
                  </div>
                </el-descriptions-item>
              </el-descriptions>
            </el-descriptions-item>-->

            <el-descriptions-item :span="6">
              <template slot="label">
                装配
              </template>
              <el-descriptions class="margin-top"  :column="3"  border>
                <el-descriptions-item>
                  <template slot="label">
                    已完成
                  </template>
                  <div style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    {{this.order.encasementComplete}}
                  </div>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    返工中
                  </template>
                  <div style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    {{this.order.encasementRework}}
                  </div>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    已报废
                  </template>
                  <div style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    {{this.order.encasementScrap}}
                  </div>
                </el-descriptions-item>
              </el-descriptions>
            </el-descriptions-item>

          </el-descriptions>

          <br>
          <br>

<!--          <el-divider content-position="left" >产品项</el-divider>-->

<!--          <el-descriptions class="margin-top"  :column="6"  border v-for="(report,index) in reports" :key="index"
          >

            <template slot="extra">

            </template>
            <el-descriptions-item :span="1" >
              <template slot="label">

                产品编码
              </template>
              <div style="width: 150px; white-space: nowrap; overflow: hidden; /*text-overflow: ellipsis*/;">
                {{report.goodsCode}}
              </div>
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label">

                产品数量
              </template>
              <div style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                {{report.goodsQuantity}}
              </div>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">

                已出货
              </template>
              <div style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                {{report.goodsShipment}}
              </div>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">

                库存
              </template>
              <div style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                {{report.goodsInventory}}
              </div>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">

                应绑定数量
              </template>
              <div style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                {{report.goodsQuantity + report.goodsScrap}}
              </div>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">

                已绑定数量
              </template>
              <div style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                {{report.realInput}}
              </div>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">

                总返工次数
              </template>
              <div style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                {{report.rework}}
              </div>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">

                已报废
              </template>
              <div style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                {{report.goodsScrap}}
              </div>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">

                订单号
              </template>
              <div style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                {{report.poId}}
              </div>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">

                产品类型
              </template>
              <div style="width: 150px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                {{ report.kind === 1 ? '喷油-装配-装箱' : report.kind === 2 ? '贴花-喷油-装配-装箱' : '未知类型' }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item :span="2">
            </el-descriptions-item>


            <el-descriptions-item :span="6" v-if="report.kind === 2">
              <template slot="label">
                贴花
              </template>
              <el-descriptions class="margin-top"  :column="3"  border>
                <el-descriptions-item>
                  <template slot="label">
                    已完成
                  </template>
                  <div style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    {{report.appliqueComplete}}
                  </div>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    返工中
                  </template>
                  <div style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    {{report.appliqueRework}}
                  </div>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    已报废
                  </template>
                  <div style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    {{report.appliqueScrap}}
                  </div>
                </el-descriptions-item>
              </el-descriptions>
            </el-descriptions-item>



            <el-descriptions-item :span="6">
              <template slot="label">
                喷油
              </template>
              <el-descriptions class="margin-top"  :column="3"  border>
                <el-descriptions-item >
                  <template slot="label">
                    已完成
                  </template>
                  <div style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    {{report.sprayComplete}}
                  </div>
                </el-descriptions-item>
                <el-descriptions-item >
                  <template slot="label">
                    返工中
                  </template>
                  <div style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    {{report.sprayRework}}
                  </div>
                </el-descriptions-item>
                <el-descriptions-item >
                  <template slot="label">
                    已报废
                  </template>
                  <div style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    {{report.sprayScrap}}
                  </div>
                </el-descriptions-item>
              </el-descriptions>
            </el-descriptions-item>




&lt;!&ndash;            <el-descriptions-item :span="6">
              <template slot="label">
                装配
              </template>
              <el-descriptions class="margin-top"  :column="3"  border>
                <el-descriptions-item>
                  <template slot="label">
                    已完成
                  </template>
                  <div style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    {{report.assembleComplete}}
                  </div>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    返工中
                  </template>
                  <div style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    {{report.assembleRework}}
                  </div>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    已报废
                  </template>
                  <div style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    {{report.assembleScrap}}
                  </div>
                </el-descriptions-item>
              </el-descriptions>
            </el-descriptions-item>&ndash;&gt;

            <el-descriptions-item :span="6">
              <template slot="label">
                装配
              </template>
              <el-descriptions class="margin-top"  :column="3"  border>
                <el-descriptions-item>
                  <template slot="label">
                    已完成
                  </template>
                  <div style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    {{report.encasementComplete}}
                  </div>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    返工中
                  </template>
                  <div style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    {{report.encasementRework}}
                  </div>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    已报废
                  </template>
                  <div style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    {{report.encasementScrap}}
                  </div>
                </el-descriptions-item>
              </el-descriptions>
            </el-descriptions-item>

          </el-descriptions>-->




        </div>
      </div>
      <div class="common-foot">
        <div class="bar"></div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  mounted() {
   const id = this.$route.params.id;
   this.$message(id);

   this.fetchData(id);
   this.fetchGoodsReports(id);
  },

  methods:{
    toScrapOrderItem(id){
      var kind = 'scrap'
      this.$router.push(`/dashboard/orderItem/${id}/${kind}`)
    },

    toRealInputOrderItem(id){
      var kind = 'realInput'
      this.$router.push(`/dashboard/orderItem/${id}/${kind}`)
    },

    toReworkOrderItem(id){
      var kind = 'rework'
      this.$router.push(`/dashboard/orderItem/${id}/${kind}`)
    },
    toInventoryOrderItem(id){
      var kind = 'inventory'
      this.$router.push(`/dashboard/orderItem/${id}/${kind}`)
    },

    fetchData(id) {

      var form = {
        poId:id,
      }

      this.$axios.get(process.env.VUE_APP_BASE_API+'/api/order/getOrderReport',{params:form})
          .then(response => {
            this.order = response.data.data.report
          })
          .catch(error => {
            console.error('Error:', error);
          });
    },
    fetchGoodsReports(id){
      var form = {
        poId:id,
      }

      this.$axios.get(process.env.VUE_APP_BASE_API+'/api/goods/report',{params:form})
          .then(response => {
            this.reports = response.data.data.reports
          })
          .catch(error => {
            console.error('Error:', error);
          });
    },


  },
  components: {},
  data() {
    return {
      order:{},
      reports:[],

    }
  }
}
</script>

<style scoped>
/* 样式可以根据需要添加 */

.custom-table .el-table {
  font-size: 12px; /* 调整表格字体大小 */
}

.common{
  width: 100%;
  height: 100%;

}

.common .common-header{
  width: 100%;


  display: flex;
  justify-content: flex-start; /* 水平靠左排列 */
  align-items: flex-end;
  flex-direction: column;
}
.common .common-header .serch{
  background: #4B6EA8;
  width: 100%;
  height: 80px;

  display: flex;
  justify-content: center; /* 水平居中 */

}
.common .common-header .serch .select-serch{
  width: 500px;
  height: 70px;

}


.common .common-header .tow-button{
  width: 100%;
  height: 80px;

  display: flex;
  justify-content: flex-start; /* 水平靠左排列 */
  align-items: flex-end; /* 垂直靠下排列 */
}

.common .common-header .tow-button .button{
  width: 100px;
  height: 30px;

  margin-bottom: 15px;
  margin-left: 15px;
}

.common .common-middle{
  width: 100%;
  height: 100%;

  display: flex;

  flex-direction: column;

}

.bar{
  width: 100%;
  height: 50px;
  background: #4B6EA8;
}


.common .common-middle .table{
  width: 100%;
  max-width: 1700px;
  margin-right: auto;
  margin-left: auto;

}
.common .common-foot{
  margin-top: 20px;
  background: #4B6EA8;
  width: 100%;
  display: flex;
}

</style>
