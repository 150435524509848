<template>
  <div>
    <el-button type="primary" @click="dialogFormVisible = true">上传订单</el-button>

    <el-dialog title="上传订单" :visible.sync="dialogFormVisible" width="40%">
      <!-- 隐藏的文件输入框 -->
      <input
          type="file"
          ref="fileInput"
          style="display: none"
          @change="handleFileChange"
          accept=".xlsx"
      />

      <!-- 上传文件按钮 -->
      <el-button type="primary" @click="triggerFileInput">上传文件</el-button>

      <el-button type="primary" @click="downloadErrorFile" v-if="errorFileUrl">
        下载错误文件
      </el-button>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      dialogFormVisible: false,
      errorFileUrl: null, // 存储错误文件下载链接
    };
  },
  methods: {
    // 触发隐藏的文件输入框
    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    // 处理文件选择并自动上传
    async handleFileChange(event) {
      const selectedFile = event.target.files[0];

      if (!selectedFile) {
        this.$message.error('请选择文件');
        return;
      }

      const formData = new FormData();
      formData.append('file', selectedFile);

      try {
        const response = await axios.post(process.env.VUE_APP_BASE_API+'/api/upload/orderExcel', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('token')}`, // 如果需要 token
          },
          responseType: 'blob', // 确保响应以 Blob 格式返回
        });

        // 处理成功响应
        if (response.status === 200) {
          this.$message.success('文件上传成功');
          this.errorFileUrl = null; // 清除之前的错误文件链接
          this.dialogFormVisible = false;
        }
      } catch (error) {
        // 处理错误响应
        if (error.response && error.response.status === 400) {
          this.$message.error('文件上传失败，生成了错误文件');

          // 将 Blob 对象转换为 URL，供下载
          const blob = error.response.data;
          this.errorFileUrl = window.URL.createObjectURL(blob);
        } else {
          this.$message.error('上传失败，请稍后重试');
        }
      } finally {
        // 清空文件输入框的值，确保可以再次选择同一文件
        this.$refs.fileInput.value = '';
      }
    },

    // 下载错误文件
    downloadErrorFile() {
      if (this.errorFileUrl) {
        const link = document.createElement('a');
        link.href = this.errorFileUrl;
        link.setAttribute('download', 'error.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
  },
};
</script>

<style>
.dialog-footer {
  text-align: right;
}
</style>
